import React, { useState, useEffect, useRef, useMemo } from "react";
import { Select, Input, Button, Drawer, Spin, Popconfirm, Upload, Modal, Switch, Tooltip, message } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
// import wordIcon from 'SRC/assets/imgs/word-icon.png';
import {
  EditOutlined,
  SendOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PauseCircleOutlined,
  SaveOutlined,
  RollbackOutlined,
  ChromeOutlined,
} from "@ant-design/icons";
import {
  getGptApiChat,
  IGptApiChat,
  gptHistoryRecently,
  gptHistorySummary,
  gptHistoryId,
  gptHistoryIdDelete,
  gptSummaryChange,
  IMessage,
  IMessageContent,
} from "SRC/api/gptdalle";
import { globalMessage } from "SRC/components/messagebar";
import { getTimeGroup } from "SRC/utils/time";
import "./index.less";
import axios from "axios";
import { MemoizedBotChatContent, MemoizedUserChatContent } from "SRC/components/messagerender";

const { TextArea } = Input;

interface IChatHistory {
  chatHistoryId: string;
  summary: string;
  mostRecentCall: string;
  itemGroup: string;
}

const gptOptions = [
  {
    value: "o1",
    label: "o1"
  },
  {
    value: "o3mini",
    label: "o3-mini"
  },
  {
    value: "GPT4",
    label: "GPT-4",
  },
  {
    value: "GPT4o",
    label: "GPT-4o"
  },
  {
    value: "o1mini",
    label: "o1-mini"
  },
  {
    value: "o1preview",
    label: "o1-preview"
  },
  {
    value: "DeepSeekR1",
    label: "DeepSeek-R1"
  }
];

export const GptTextGeneration: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [gptType, setGptType] = useState("GPT4o");
  const [open, setOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState<IChatHistory[]>([]);
  const [currentChatID, setCurrentChatID] = useState<string>("");
  const [currentChatContent, setCurrentChatContent] = useState<IMessage[]>(
    []
  );
  const [generating, setGenerating] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>("");
  const [role, setRole] = useState<string>("assistant");
  const [loading, setLoading] = useState<boolean>(false);
  const [inputHeight, setInputHeight] = useState<number | undefined>(0);
  const controllerRef = useRef<AbortController | null>(null);
  const [editShow, setEditShow] = useState(false);
  const [systemPrompt, setSystemPrompt] = useState("");
  const [tempPrompt, setTempPrompt] = useState("");
  const [editSwitchState, setEditSwitchState] = useState(false);
  const [editedSummary, setEditedSummary] = useState("");
  const [editingId, setEditingId] = useState("null");
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [editedContent,setEditedContent] = useState("");
  const [contentEditingId, setContentEditingId] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isWebSearch, setIsWebSearch] = useState<boolean>(false);

  interface UploadedFile {
    id: string;
    merchantId: string;
    contentType: string;
    fileSize: number;
    createdAt: string;
    url: string;
    fileName: string;
    suffix: string;
  }

  const [fileList, setFileList] = useState<any>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const uploadStatusRef = useRef({});
  
  const handleFileChange = (info) => {
    setFileList(info.fileList);
    if (info.file.status === 'done') {
      setUploadedFiles(prev => [...prev, info.file.response]); // 保存返回的数据
      setIsUploading(false);
    }
    else if (info.file.status === 'removed') {
      if(uploadStatusRef.current[info.file.uid]){
        uploadStatusRef.current[info.file.uid].canceled = true;
      }
      setUploadedFiles(prev => prev.filter(file => file.id !== info.file.response.id));
    }
    else if (info.file.status === 'error') {
      console.log("上传失败");
      setIsUploading(false);
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleFilePreview = async (file) => {
    file.preview = await getBase64(file.originFileObj);

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleContentEdit = (mid, defaultContent) => {
    setContentEditingId(mid)
    setEditedContent(defaultContent)
  }

  const handleEditCancel = () => {
    setContentEditingId("")
  }

  const handleReSend = () => {
    onUserClick(true)
  }

  const handleContentChange = (newContent) => {
    setEditedContent(newContent);
  };

  const handleReGenerate = () => {
    onUserClick(false, true)
  }

  useEffect(() => {
    onGptHistorySummary();
  }, []);

  useEffect(() => {
    if (editSwitchState) {
      handleSystemPrompt()
    }
  }, [systemPrompt])

  useEffect(() => {
    const dialogueDiv = document.getElementById("dialogueDiv");
    dialogueDiv?.scroll({ top: dialogueDiv.scrollHeight, behavior: "smooth" });
  }, [currentChatContent, inputHeight]);

  const onGptHistorySummary = () => {
    setLoading(true);
    gptHistorySummary()
      .then((res: any) => {
        const newData = res.data.map((item: any) => {
          return {
            ...item,
            itemGroup: getTimeGroup(item.mostRecentCall),
          };
        });
        setChatHistory(newData);
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setEditingId("");
    setOpen(false);
  };

  const handleChange = (value: string) => {
    setFileList([]);
    setUploadedFiles([]);
    setGptType(value);
  };

  const onUserClick = (reSend:boolean = false, reGenerate:boolean = false) => {
    setGenerating(true);
    let newContent: IMessage[];
    let params: IGptApiChat;
    if(!reSend && !reGenerate) {
      const userMessageContent: IMessageContent[] = [{ type: "text", text: userInput }];

      if ( uploadedFiles && uploadedFiles.length > 0) {
        uploadedFiles.forEach(file => {
          if ([".png", ".jpg", ".jpeg"].includes(file.suffix.toLowerCase()) && (gptType === 'GPT4o' || gptType === "o1")) {
            userMessageContent.push({ type: "image_url", imageUrl: { url: file.url } });
          } else if ([".doc", ".docx", ".xls", ".xlsx"].includes(file.suffix.toLowerCase())) {
            userMessageContent.push({ type: "attachment", fileName: file.fileName, attachment: file.id.toString() });
          }
        });
      }
  
      const mid = uuidv4()
    
      params= {
        historyId: currentChatID,
        version: gptType,
        isWebSearch: isWebSearch,
        messages: [...currentChatContent, { role: "user", mid: mid, content: userMessageContent }],
        stream: true,
      };
      newContent = [
        ...currentChatContent,
        { role: "user", mid: mid, content: userMessageContent },
        { role, content: [{ "type": "text", "text": ""}] },
      ];
    } else if(reSend && !reGenerate) {
      let targetIndex = -1;
      const messages = [...currentChatContent]
      messages.forEach((message, index) => {
        if (message.mid === contentEditingId) {
            // 修改目标 mid 的内容
            message.content.forEach(contentItem => {
                if (contentItem.type === 'text') {
                    contentItem.text = editedContent;
                }
            });
            targetIndex = index;
        }
      });
      messages.splice(targetIndex + 1);
      params = {
        historyId: currentChatID,
        version: gptType,
        isWebSearch: isWebSearch,
        messages: messages,
        stream: true,
      }
      newContent = [
        ...messages,
        { role, content: [{ "type": "text", "text": ""}] },
      ]
      setContentEditingId("")
    } else {
      const messages = currentChatContent.slice(0, -1);
      params = {
        historyId: currentChatID,
        version: gptType,
        isWebSearch: isWebSearch,
        messages: messages,
        stream: true,
      }
      newContent =  [
        ...messages,
        { role, content: [{ "type": "text", "text": ""}] },
      ]
    }

    setCurrentChatContent(newContent);

    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;

    // 获取文件流，触发渲染
    let lastResponseLength = 0;  
    let accumulatedText = '';  
      
    const onDownloadProgress = (progressEvent: any) => {  
      // 请求错误时，获取错误信息来展示  
      if (progressEvent.event.currentTarget.statusText === "Bad Request") {  
        const response = JSON.parse(  
          progressEvent.event.currentTarget.responseText  
        );  
        const errorText = response.error.message;  
        if (errorText != "") {  
          newContent[newContent.length - 1] = {  
            role: "assistant",  
            content: [{ "type": "text", "text": errorText }],  
          };  
          setCurrentChatContent(_.cloneDeep(newContent)); 
        }  
        return;  
      }  
      
      const responseText = progressEvent.event.currentTarget.responseText;  
        
      // 获取自上次处理后新增的数据  
      const newText = responseText.substring(lastResponseLength);  
      lastResponseLength = responseText.length;  
      
      const lines = newText.split("\n\n");  
      
      lines.forEach(line => {  
        if (!line.trim()) return;  
      
        if (line.startsWith("data: ")) {  
          const jsonData = line.replace("data: ", "").trim();  
          try {  
            if (jsonData === "[DONE]") {  
              // 如果接收到结束标识，可以在此进行清理工作  
              return;  
            }  
            const data = JSON.parse(jsonData);  
              
            if (data.role === "assistant" && data.content) {  
              // 累加接收到的内容  
              accumulatedText += data.content;  
      
              newContent[newContent.length - 1] = {  
                role: data.role,  
                content: [{ "type": "text", "text": accumulatedText }]  
              };  
        
            }  else if (data.role === "drawer" && data.url) {
              newContent.push({
                role: data.role,
                content: [{ "type": "text", "text": data.url}]
              })
            }
            // 更新聊天内容  
            setCurrentChatContent(_.cloneDeep(newContent));
      
          } catch (error) {  
            console.error("JSON Parsing Error: ", error);  
          }  
        } 
      });  
    }; 
    setUserInput("");
    setFileList([]);
    setUploadedFiles([]);
    getGptApiChat(onDownloadProgress, params, signal)
      .then((_res) => {
        if (currentChatID === "") {
          onGptHistoryRecently();
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("请求已取消");
        } else {
          globalMessage(err);
        }
      })
      .finally(() => {
        setGenerating(false);
      });
  };

  const handStopGen = () => {
    if (controllerRef.current) {
      controllerRef.current.abort(); // 取消请求
    }
  };

  const onGptHistoryRecently = () => {
    gptHistoryRecently()
      .then((res: any) => {
        const { messages } = res.data;
        setCurrentChatID(res.data.chatHistoryId);
        setCurrentChatContent(messages);
        const newChatHistory = {
          chatHistoryId: res.data.chatHistoryId,
          summary: res.data.summary,
          mostRecentCall: res.data.mostRecentCall,
          itemGroup: "today",
        };
        setChatHistory([newChatHistory, ...chatHistory]);
      })
      .catch((err) => {
        globalMessage(err);
      });
  };

  const getHistoryChat = (history: IChatHistory) => {
    setLoading(true);
    gptHistoryId(history.chatHistoryId)
      .then((res: any) => {
        const { messages } = res.data;
        setCurrentChatID(history.chatHistoryId);
        setCurrentChatContent(messages);
        if (messages[0]["role"] === "system") {
          setEditSwitchState(true)
          setSystemPrompt(messages[0]["content"][0]["text"])
          setTempPrompt(messages[0]["content"][0]["text"])
        }
        else {
          setEditSwitchState(false)
        }
        onClose();
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const deleteHistoryChat = (e: any, history: IChatHistory) => {
    e.stopPropagation();
    setLoading(true);
    gptHistoryIdDelete(history.chatHistoryId)
      .then((res: any) => {
        // 删除记录中的对话数据
        const newChatHistory = chatHistory.filter(
          (item) => history.chatHistoryId !== item.chatHistoryId
        );
        setChatHistory(newChatHistory);
        if (currentChatID === history.chatHistoryId) {
          setCurrentChatID("");
          setCurrentChatContent([]);
        }
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const createChat = () => {
    setCurrentChatID("");
    setCurrentChatContent([]);
    setEditSwitchState(false);
    setSystemPrompt("");
    setTempPrompt("");
    onClose();
  };

  const handleSwitchOn = (checked) => {
    handleSystemPrompt()
    setEditSwitchState(checked)
  };

  const handleSystemPrompt = () => {
    const sysPromptContent: IMessageContent[] = [{ type: "text", text: systemPrompt }];
    const sysMessage = [{ role: "system", content: sysPromptContent }]
    if ( currentChatContent.length === 0) {
      setCurrentChatContent(_.cloneDeep(sysMessage));
    } else if (currentChatContent[0]['role'] === "system") {
      const updatedChatContent = _.cloneDeep(currentChatContent);
      updatedChatContent[0] = sysMessage[0];
      setCurrentChatContent(updatedChatContent);
    } else {
      const updatedChatContent = _.cloneDeep(currentChatContent);
      updatedChatContent.unshift(sysMessage[0]);
      setCurrentChatContent(updatedChatContent);
    }
  }

  const handleSwitchOff = (checked) => {
    if ( currentChatContent.length != 0 && currentChatContent[0]['role'] === "system") {
      const updatedChatContent = _.cloneDeep(currentChatContent);
      updatedChatContent.shift();
      setCurrentChatContent(updatedChatContent);
    }
    setEditSwitchState(checked)
  };

  const onChangeSwitch = (checked) => {
    if (checked) {
      handleSwitchOn(checked);
    } else {
      handleSwitchOff(checked);
    }
  };
  
  const onEditShow = () => {
    setEditShow(true)
  }

  const onEditSave = () => {
    setSystemPrompt(tempPrompt)
    setEditShow(false)
  }

  const onEditClose = () => {
    setTempPrompt(systemPrompt)
    setEditShow(false)
  }

  const handleInputChange = (e) => {
    setTempPrompt(e.target.value);
  };

  const uploadImg = async(file) => {
    const uploadFile = new File([file], file.name, { type: file.type });
    // const previewUrl = URL.createObjectURL(file);
    const isImage = file.type.startsWith("image/");
    const previewUrl = isImage ? URL.createObjectURL(file) : require("SRC/assets/imgs/word-icon.png");
    const uid = Date.now()

    const newFileList = [...fileList, {
      uid,
      name: file.name,
      status: 'uploading',
      originFileObj: uploadFile,
      thumbUrl: previewUrl,
    }];
    setFileList(newFileList);
    uploadStatusRef.current[uid] = { canceled: false };

    // Trigger upload
    const formData = new FormData();
    formData.append('file', uploadFile);
    const res = axios.post('/mediastorage/api/MediaFile/GPTUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.status == 200 && !uploadStatusRef.current[uid].canceled) {
          setFileList(prevFileList =>
            prevFileList.map(file =>
              file.uid === uid
                ? { ...file, status: 'done', response: response.data}
                : file
            )
          );
          setUploadedFiles(prev => [...prev, response.data]);
        } else if (response.status == 401) {
          globalMessage("global.message.user.login.expired");
          window.location.href = "/login"
        }
      }).finally(() => {
        delete uploadStatusRef.current[uid];
        setIsUploading(false)
      })
  }

  const handlePaste = async (e) => {
    const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (gptType === "GPT4o" || gptType === "o1") {
      allowedFileTypes.unshift('image')
    }
    if (!isWebSearch) {
      const clipboardItems = e.clipboardData.items;
      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];
        const itemType = item.type;
        if (allowedFileTypes.some(type => itemType.indexOf(type) !== -1)) {
          const file = item.getAsFile();
          if (file) {
            setIsUploading(true)
            await uploadImg(file)
          }
        }
      }
    }
  };
  const handleDrop = async (e) => {
    const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (gptType === "GPT4o" || gptType === "o1") {
      allowedFileTypes.unshift('image')
    }
    if (!isWebSearch) {
      e.preventDefault();
      const dt = e.dataTransfer;
      const files = dt.files;
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileType = file.type;
        if (allowedFileTypes.some(type => fileType.indexOf(type) !== -1)) {
          setIsUploading(true)
          await uploadImg(file);
        }
      }
    }
  };

  const saveSummary = (history) => {
    setEditLoading(true)
    history.summary = editedSummary
    const updatedHistory = { ...history, summary: editedSummary };
    gptSummaryChange({"ChatHistoryId": history.chatHistoryId, "Summary": history.summary})
    .then(() => {
      const updatedChatHistory = chatHistory.map(item => (item.chatHistoryId === history.chatHistoryId ? updatedHistory : item));
      setChatHistory(updatedChatHistory);
    })
    .catch((err) => {
      console.log(err)
    }).finally(() => {
      setEditingId("")
      setEditedSummary("")
      setEditLoading(false)
    })
  }

  const cancelEdit = () => {
    setEditingId("")
    setEditedSummary("")
  }
  
  const startEdit = (history) => {
    setEditedSummary("")
    setEditingId(history.chatHistoryId)
  }

  const memoizedChatContent = useMemo(() => {
    return currentChatContent.map((message: IMessage, index) => {
      const isLastItem = index === currentChatContent.length - 1;
      if (message.role === "user") {
        return (
          <div key={index}>
            {
              message.content.map((content, contentIndex) => {
                return (
                  <div key={contentIndex}>
                    <MemoizedUserChatContent
                      content={content}
                      mid={message.mid}
                      handleContentEdit={handleContentEdit}
                      editedContent={editedContent}
                      handleContentChange={handleContentChange}
                      onEditCancel={handleEditCancel}
                      onReSend={handleReSend}
                      contentEditingId={contentEditingId}
                      isWebSearch={isWebSearch}
                    />
                  </div>
                );
              })
            }
          </div>
        );
      } else if (message.role === "assistant") {
        const botContent = message.content[0]?.text || ''; 
        return (
          <div key={index}>
            <MemoizedBotChatContent 
              content={botContent}
              isLastItem={isLastItem}
              handleReGenerate={handleReGenerate} 
              isWebSearch={isWebSearch}
            />
          </div>
        );
      } else if (message.role === "drawer") {
        return (
          <div className="chat-bot" key={index}>
            <img className="chat-bot-image" src={message.content[0]?.text} alt="image" />
          </div>
        )
      }
    });
  }, [currentChatContent, handleContentEdit, handleContentChange, handleEditCancel, handleReSend, contentEditingId, handleReGenerate, isWebSearch]);

  const handleBeforeUpload = (file) => {
    setIsUploading(true);
    return true;
  }

  const handleSetWebSearch = () => {
    if (!generating) {
      setIsWebSearch(!isWebSearch)
    }
  }

  return (
    <div className="text-gpt-wrapper">
      <div className="chat-wrapper">
        <div className="gpt-type">
          <label>当前版本：</label>
          <Select
            options={gptOptions}
            value={gptType}
            style={{ width: 120 }}
            onChange={handleChange}
          />
          <span className="editSpan" onClick={onEditShow}>自定义AI助手:</span>
          <Switch className="editSwitch" onChange={onChangeSwitch} checked={editSwitchState} />
        </div>
        {currentChatContent.length > 1 ? (
          <div className="chat-info" id="dialogueDiv">
            {memoizedChatContent}
          </div>
        ) : (
          <div className="chat-main">
            <label>文本生成</label>
            <p>欢迎来到文本生成模板，你可以在这里询问任何问题！</p>
            <img src={require("SRC/assets/imgs/gpt.jpg")} />
          </div>
        )}
        <div className="chat-input">
          <Upload
            action="/mediastorage/api/MediaFile/GPTUpload"
            fileList={fileList}
            onPreview={handleFilePreview}
            onChange={handleFileChange}
            beforeUpload={handleBeforeUpload}
            multiple={true}
            listType="picture"
            accept={(gptType === "GPT4o" || gptType === "o1") ? ".jpg, .jpeg, .png, .docx, .doc, .xls, .xlsx" : ".docx, .doc, .xls, .xlsx"}
            disabled= {isWebSearch}
          >
            <Tooltip title="上传附件" placement="left">
              <UploadOutlined className="upload-item-icon" />
            </Tooltip>
          </Upload> 
          <Tooltip title="搜索网页" placement="right">
            <ChromeOutlined 
              className="websearch-item-icon"
              onClick={handleSetWebSearch}
              style={{
                color: isWebSearch ? '#1890ff' : '#c1c1c1', 
              }}
            />
          </Tooltip>
          <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          { generating ?
            <Button
              size="small"
              className="site-form-item-btn"
              type="primary"
              icon={<PauseCircleOutlined />}
              onClick={handStopGen}
            />
            :
            <Button
              size="small"
              className="site-form-item-btn"
              type="primary"
              icon={<SendOutlined />}
              disabled={!userInput || isUploading}
              onClick={() => onUserClick()}
            />
          }
          <Input.TextArea
            id='dialogueInput'
            autoSize={{
              minRows: 1,
              maxRows: 11
            }}
            allowClear
            placeholder="请输入..."
            onPaste={handlePaste}
            onDrop={handleDrop}
            value={userInput}
            style={{ paddingBottom: '28px' }}
            onChange={(e) => {
              setUserInput(e.target.value);
              setTimeout(() => {
                const dialogueInput = document.getElementById("dialogueInput")
                setInputHeight(dialogueInput?.offsetHeight)              
              }, 500)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (userInput && !generating && !isUploading) {
                  onUserClick();
                }
              }
            }}
            onPressEnter={() => null}
          />
        </div>
      </div>
      <div className="record-wrapper">
        <Button
          disabled={generating}
          icon={<LeftCircleOutlined />}
          type="primary"
          style={{ position: "static" }}
          onClick={showDrawer}
        />
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        destroyOnClose={true}
        getContainer={false}
      >
        <div className="drawer-header" onClick={onClose}>
          <RightCircleOutlined className="drawer-icon-right" />
          <span className="drawer-header-text">历史记录</span>
        </div>
        <Spin spinning={loading}>
          <div className="drawer-content">
            <Button
              className="drawei-content-create"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={createChat}
            >
              新建对话
            </Button>
            <div className="drawer-content-date">
              {chatHistory.map((history: IChatHistory, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {index ===
                      chatHistory.findIndex(
                        (item) => item.itemGroup === history.itemGroup
                      ) && (
                      <p className="drawer-content-title">
                        {t(`pages.GPT.${history.itemGroup}`)}
                      </p>
                    )}
                    {editingId === history.chatHistoryId ? (
                      <div>
                        { editLoading ? (
                          <div className="drawer-content-input">
                            <Spin className="summaryLoading" />
                          </div>
                        ) : (
                          <div className={`drawer-content-input ${
                            history.chatHistoryId === currentChatID &&
                            "drawer-content-input-select"
                          }`}>
                            <Input
                              type="text"
                              value={editedSummary}
                              className="historyEditInput"
                              onChange={(e) => setEditedSummary(e.target.value)}
                              onPressEnter={() => saveSummary(history)}
                            />
                            <SaveOutlined onClick={() => saveSummary(history)} title="保存" className="historyEditIcon" />
                            <RollbackOutlined onClick={() => cancelEdit()} title="返回"  className="historyEditIcon" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <p
                        className={`drawer-content-input ${
                          history.chatHistoryId === currentChatID &&
                          "drawer-content-input-select"
                        }`}
                        onClick={() => getHistoryChat(history)}
                      >
                        <span className="summaryContent">{history.summary}</span>
                        <EditOutlined
                          className="drawer-content-icon-btn"
                          onClick={(e) => {
                            e.stopPropagation()
                            startEdit(history)
                          }}
                        />
                        <Popconfirm
                          title="删除对话"
                          description="确认删除该对话？"
                          onConfirm={(e) => deleteHistoryChat(e, history)}
                          onCancel={(e: any) => e.stopPropagation()}
                          okText="确认"
                          cancelText="取消"
                        >
                          <DeleteOutlined
                            className="drawer-content-icon-btn"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Popconfirm>
                      </p>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Spin>
        <div className="drawer-footer">系统仅保存30天对话历史</div>
      </Drawer>
      <Modal 
        open={editShow}
        okText="保存"
        cancelText="取消"
        closable={false}
        onOk={onEditSave}
        onCancel={onEditClose}
        width={800}
        centered
      >
        <div style={{ fontSize: '20px', margin: '10px 0'}}>自定义AI助手:</div>
        <TextArea
          value={tempPrompt}
          onChange={handleInputChange}
          style={{ width: '100%', height: '200px' }} 
        />
      </Modal>
    </div>
  );
};
